
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PublicFormSuccess",
  props: {
    query: {},
  },
  setup() {
    const response = {
      EN: {
        DOCUMENT_FORM: {
          title: "Documents Submitted Successfully!",
          description: "Your claim documents have been uploaded successfully. We will process your request shortly.",
        }
      },
      FR: {
        DOCUMENT_FORM: {
          title: "Documents soumis avec succès !",
          description: "Vos documents de réclamation ont été téléchargés avec succès. Nous traiterons votre demande sous peu.",
        }
      }
    };
    return {response}
  }

})
